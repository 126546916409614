<template>
  <InvoicesAndCreditNotes :isSales="false" />
</template>
  
  <script>
import InvoicesAndCreditNotes from "../shared/InvoicesAndCreditNotes.vue";
export default {
  components: {
    InvoicesAndCreditNotes,
  },
};
</script>
  
  <style>
</style>